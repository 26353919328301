import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService, ApiSingleResult, ListOptions } from '@capturum/api';
import { DateType, InfoTableFilterConfig } from '@shared/modules/info-table-filters/info-table-filter-config.model';
import { FilterMatchMode, FilterType } from '@capturum/ui/api';
import { DateHelper } from '@core/utils/date.helper';
import { TranslateService } from '@ngx-translate/core';
import { FarmService } from '@core/api/farm.service';
import { DxpIndexableBaseDataService } from '@core/indexDb/services/dxp-indexable-base-data.service';
import { Observable } from 'rxjs';
import { Fulfillment, FulfillmentShipments } from '@core/models/fulfillment.model';
import { ApiIndexResult } from '@capturum/api/lib/interfaces/api.interface';
import { BaseKey } from '@core/enums/base-key.enum';
import { DxpDocument } from '@core/models/document.model';
import { map, switchMap } from 'rxjs/operators';
import { DividedStockHelper } from '@core/utils/divided-stock.helper';
import { Shipment } from '@core/models/shipment.model';
import { SortDirection } from '@core/enums/ui-general.enum';

@Injectable({
  providedIn: 'root',
})
export class FulfillmentService extends ApiService<Fulfillment> {
  protected endpoint = 'fulfillment';

  constructor(
    public apiHttp: ApiHttpService,
    private farmService: FarmService,
    private translateService: TranslateService,
    private dxpIndexableBaseDataService: DxpIndexableBaseDataService,
  ) {
    super(apiHttp);
  }

  public getFilterConfig(): InfoTableFilterConfig[] {
    return [
      {
        field: 'global',
        icon: 'fas fa-search',
        type: FilterType.INPUT,
        placeholder: this.translateService.stream('dxp.fulfillment.type_to_search.label'),
        globalFilter: true,
      },
      {
        field: 'farm_id',
        type: FilterType.MULTISELECT,
        matchMode: FilterMatchMode.IN,
        placeholder: this.translateService.stream('dxp.fulfillment.farm_id.label'),
        options: this.farmService.getAllFarms(),
        filterSearchable: true,
        icon: 'fas fa-fw fa-farm',
      },
      {
        field: 'packing_order_status_base_data_value_id',
        type: FilterType.MULTISELECT,
        matchMode: FilterMatchMode.IN,
        options: this.dxpIndexableBaseDataService.getWeightedBaseDataKeyValues(BaseKey.packingOrderStatus),
        placeholder: this.translateService.stream('dxp.fulfillment.shipment_status.label'),
        icon: 'fas fa-pallet',
        filterSearchable: true,
        sortAlphabetically: false,
      },
      {
        field: 'shipmentInformation.shippingSchedule.expected_arrival_at',
        type: FilterType.DATEPICKER,
        matchMode: FilterMatchMode.BETWEEN,
        dateRange: true,
        dateType: DateType.DATETIME,
        placeholder: this.translateService.stream('dxp.fulfillment.expected_arrival_at.label'),
        value: DateHelper.getDayAgoAndDayForward(1, 2),
      },
    ];
  }

  public getDocumentsById(entityId: string, options?: ListOptions): Observable<ApiSingleResult<DxpDocument>> {
    let url = `/${this.endpoint}/${entityId}/documents`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get<ApiSingleResult<DxpDocument>>(url);
  }

  public getAwaitingShipments(options?: ListOptions): Observable<ApiIndexResult<Shipment>> {
    let url = `/${this.endpoint}/shipments/awaiting`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get(url);
  }

  public getPlannedShipments(options?: ListOptions): Observable<ApiIndexResult<FulfillmentShipments>> {
    let url = `/${this.endpoint}/shipments/planned`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get(url);
  }

  public editWarehouseDelivery(
    shipmentId: string,
    data: { expected_arrival_at: Date; comment: string },
    options?: ListOptions,
  ): Observable<ApiIndexResult<FulfillmentShipments>> {
    let url = `/${this.endpoint}/${shipmentId}/warehouse-delivery`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.patch(url, data);
  }

  public recreateBatches(shipmentId: string): Observable<void> {
    const url = `/${this.endpoint}/${shipmentId}/recreate-batches`;

    return this.apiHttp.post(url, {});
  }

  public recreateLots(shipmentId: string): Observable<void> {
    const url = `/${this.endpoint}/${shipmentId}/recreate-lots`;

    return this.apiHttp.post(url, {});
  }

  public splitShipments(shipmentId: string, data: { batch_ids: string[]; _force?: boolean }): Observable<void> {
    const url = `/${this.endpoint}/${shipmentId}/split`;

    return this.apiHttp.post(url, data);
  }

  public getFulfillmentListByStatusAndAllFarms(
    statusKeys: string[] = ['packing_order_status_received_at_destination'],
    options?: ListOptions,
  ): Observable<Shipment[]> {
    return this.dxpIndexableBaseDataService.getBaseDataValuesByDataKey(BaseKey.packingOrderStatus).pipe(
      map((statusList) => {
        return statusList
          .filter((status) => {
            return statusKeys.includes(status.key);
          })
          .map((item) => {
            return item.value;
          });
      }),
      switchMap((statusIds: string[]) => {
        return this.getAwaitingShipments({
          perPage: 10,
          include: [
            'farmWithoutScope',
            'shipmentInformation.agentOrganisation',
            'shipmentInformation.clientOrganisation',
            'shipmentInformation.shippingSchedule',
          ],
          sort: [
            {
              field: 'shipmentInformation.shippingSchedule.expected_arrival_at',
              direction: SortDirection.desc,
            },
          ],
          filters: [
            {
              field: 'packing_order_status_base_data_value_id',
              value: statusIds,
              operator: FilterMatchMode.IN,
            },
          ],
          ...options,
        }).pipe(
          map((response) => {
            return response?.data;
          }),
        );
      }),
    );
  }

  public getFulfillmentToConfirmByAllFarms(id: string, mapping = true): Observable<Shipment> {
    return this.get<Shipment>(id, {
      include: [
        'farmWithoutScope',
        'shipmentInformation.agentOrganisation',
        'shipmentInformation.clientOrganisation',
        'shipmentInformation.shippingSchedule',
        'packingList',
      ],
    }).pipe(
      map((data) => {
        return mapping ? DividedStockHelper.setDividedStock(data) : data;
      }),
    );
  }
}
